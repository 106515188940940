@font-face {
  font-family: "fira_sans_condensedbook";
  src: url("../../../assets/fonts/firasanscondensed-book-webfont.eot");
  src: url("../../../assets/fonts/firasanscondensed-book-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/fonts/firasanscondensed-book-webfont.woff2")
      format("woff2"),
    url("../../../assets/fonts/firasanscondensed-book-webfont.woff")
      format("woff"),
    url("../../../assets/fonts/firasanscondensed-book-webfont.svg#fira_sans_condensedbook")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "fira_sans_condensedmedium";
  src: url("../../../assets/fonts/firasanscondensed-medium-webfont.eot");
  src: url("../../../assets/fonts/firasanscondensed-medium-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/fonts/firasanscondensed-medium-webfont.woff2")
      format("woff2"),
    url("../../../assets/fonts/firasanscondensed-medium-webfont.woff")
      format("woff"),
    url("../../../assets/fonts/firasanscondensed-medium-webfont.svg#fira_sans_condensedmedium")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

.contain-login {
  display: flex;
  justify-content: center;
}
.contain-info-login {
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: rgba(5, 245, 189, 0.54);
  background: -moz-linear-gradient(
    -45deg,
    rgba(5, 245, 189, 0.54) 4%,
    rgba(15, 243, 191, 0.54) 7%,
    rgba(229, 199, 229, 0.86) 72%,
    rgba(239, 190, 237, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right bottom,
    color-stop(4%, rgba(5, 245, 189, 0.54)),
    color-stop(7%, rgba(15, 243, 191, 0.54)),
    color-stop(72%, rgba(229, 199, 229, 0.86)),
    color-stop(100%, rgba(239, 190, 237, 1))
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(5, 245, 189, 0.54) 4%,
    rgba(15, 243, 191, 0.54) 7%,
    rgba(229, 199, 229, 0.86) 72%,
    rgba(239, 190, 237, 1) 100%
  );
  background: -o-linear-gradient(
    -45deg,
    rgba(5, 245, 189, 0.54) 4%,
    rgba(15, 243, 191, 0.54) 7%,
    rgba(229, 199, 229, 0.86) 72%,
    rgba(239, 190, 237, 1) 100%
  );
  background: -ms-linear-gradient(
    -45deg,
    rgba(5, 245, 189, 0.54) 4%,
    rgba(15, 243, 191, 0.54) 7%,
    rgba(229, 199, 229, 0.86) 72%,
    rgba(239, 190, 237, 1) 100%
  );
  background: linear-gradient(
    135deg,
    rgba(5, 245, 189, 0.54) 4%,
    rgba(15, 243, 191, 0.54) 7%,
    rgba(229, 199, 229, 0.86) 72%,
    rgba(239, 190, 237, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#05f5bd', endColorstr='#efbeed', GradientType=1 );
}

.contain-info-login h1 {
  color: #fff;
  font-size: 50px;
  margin: 0;
  padding: 0;
  width: 400px;
  text-align: center;
  line-height: 1.2;
  font-family: "fira_sans_condensedmedium";
}
.contain-info-login p {
  color: #fff;
  font-size: 25px;
  margin: 0;
  padding: 0;
  width: 400px;
  text-align: center;
  line-height: 1.2;
  font-family: "fira_sans_condensedbook";
}
.logo-login {
  width: 190px;
}
.container-cohete {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon-cohete {
  width: 140px;
}
.contain-form-login {
  width: 50%;
  height: 100vh;
  background-color: #283a50;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-group-login {
  margin: 30px 0;
}
.form-group-login input {
  border: 0;
  width: 300px;
  background-color: transparent;
  color: #fff;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}
.form-group-login input::placeholder {
  font-family: "fira_sans_condensedbook";
  color: #fff;
}

.form-group-login input:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
.contain-button-login {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contain-button-login button {
  background-color: #fabdf3;
  width: 140px;
  border: 0;
  border-radius: 10px;
  padding: 5px 15px;
}
.contain-button-login p {
  color: #fff;
  font-size: 13px;
  font-family: "fira_sans_condensedbook";
}

@media (max-width: 768px) {
  .contain-login {
    display: flex;
    flex-direction: column;
  }
  .contain-info-login {
    width: 100%;
  }
  .contain-form-login {
    width: 100%;
  }
}
