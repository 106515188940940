*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  font-size: 62.5%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
body {
  /* font-family: "Open Sans", sans-serif; */
  line-height: 1.6;
  font-size: 1.5rem;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 20px;
}
p:last-of-type {
  margin-bottom: 0;
}
a {
  color: #0e1318;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
}

img {
  max-width: 100%;
  height: auto;
  border: 0;
  vertical-align: middle;
}
small {
  font-size: 80%;
}
big {
  font-size: 120%;
}
hr {
  border: 1px solid;
  border-bottom: 0;
  margin: 20px 0;
}
ol,
ul {
  padding-left: 40px;
  margin-top: 1em;
  margin-bottom: 1em;
}
ul {
  list-style: disc;
}
blockquote p {
  margin: 0;
}

#app {
  overflow: hidden;
}
